.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(7px);
  width: 100%;
  height: 100%;
  z-index: 7;
}

.pageTourBoxWrap {
  // border: 1px solid yellow;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}

.mobileTourBox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1.5rem;
}

.centerTourBox {
  display: grid;
  place-items: center;
  padding-bottom: initial;
}

.pageTourBoxContainer {
  border: 2px solid #333333;
  border-radius: 7px;
  background: #202020;
  width: 90%;
  max-height: fit-content; // fit-content
  padding: 1.25rem;
  z-index: 1;

  &.gradientBorder {
    position: relative;
    border: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid transparent;
      border-radius: 7px;

      background: linear-gradient(to left, #483dc2, #d556f5) border-box;
      background: -webkit-linear-gradient(to left, #483dc2, #d556f5) border-box;
      background: -moz-linear-gradient(to left, #483dc2, #d556f5) border-box;
      background: -o-linear-gradient(to left, #483dc2, #d556f5) border-box;
      background: -ms-linear-gradient(to left, #483dc2, #d556f5) border-box;

      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask: -webkit-linear-gradient(#fff 0 0) padding-box,
        -webkit-linear-gradient(#fff 0 0);
      -moz-mask: -moz-linear-gradient(#fff 0 0) padding-box,
        -moz-linear-gradient(#fff 0 0);
      -o-mask: -o-linear-gradient(#fff 0 0) padding-box,
        -o-linear-gradient(#fff 0 0);
      -ms-mask: -ms-linear-gradient(#fff 0 0) padding-box,
        -ms-linear-gradient(#fff 0 0);

      mask-composite: exclude;
      // -webkit-mask-composite: destination-out;

      z-index: -1;
    }
  }
}

.boxTitle {
  // border: 1px solid red;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.boxContent {
  // border: 1px solid green;
  max-height: 90px;
  margin-bottom: 1.25rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  font-family: 'archiathin';
  overflow: scroll;

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(70, 70, 70);
  }
}

.boxControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .pageTourBoxContainer {
    // max-width: 400px;
    max-width: 80%;
  }

  .boxContent {
    max-height: 150px;
  }
}

@media (min-width: 769px) {
  .pageTourBoxContainer {
    max-width: 325px;
  }
}

@media (min-width: 1001px) {
  .pageTourBoxContainer {
    max-width: 400px;
  }
}
