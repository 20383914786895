.container {
  height: 100px;
  width: 100%;
  margin: 1em auto;
  position: relative;
}

.outer {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  left: 0;
  background-size: contain;
  background-position: center;
  right: 0;
  background-repeat: no-repeat;
}

.mid {
  height: 90px;
  width: 90px;
  margin: 0 auto;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  background-size: contain;
  background-position: center;
  right: 5px;
  background-repeat: no-repeat;
}

.inner {
  height: 75px;
  width: 75px;
  margin: 0 auto;
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 0;
  background-size: contain;
  background-position: center;
  right: 0;
  background-repeat: no-repeat;
}

.icon {
  height: 40px;
  width: 40px;
  filter: drop-shadow(0px 0px 1rem #161616);
  position: absolute;
  top: 28px;
  bottom: 22px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
}
