.outer {
  // border: 1px solid blue;
  grid-area: chart;
  margin-bottom: 10rem;
}

.container {
  // border: 1px solid red;
  position: relative;
}

.wrap {
  display: inline-block;
  border-radius: 15px;
  border: 2px solid hsl(240, 6%, 20%);
  // border: 1px solid yellow;
  padding: 0px 2px 1em 0;
  background: #121212;
  position: relative;
}

.header {
  position: absolute;
  right: 5em;
  top: 1em;
  z-index: 3;
}

.legend {
  width: 237px;
  position: absolute;
  left: 1rem;
  border-radius: 7px;
  padding: 1em;
  top: 1em;
  background: #161618;
  border: 1px solid hsl(240, 6%, 25%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  font-family: archiaregular;
  color: #d0d0d0;
  z-index: 4;
}

// @media (max-width: 1026px) {
//   .outer {
//     width: 100%;
//     margin: 0 auto 2em;
//   }

//   .container {
//     margin: 0 0 0.5em;
//     position: relative;
//   }

//   .wrap {
//     display: flex;
//     position: relative;
//     flex-direction: column-reverse;
//     // margin: 0 10px;
//   }

//   .header {
//     position: absolute;
//     right: 5em;
//     top: 0.75em;
//     z-index: 50;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 13px;
//   }
// }

// @media (max-width: 1000px) {
//   .outer {
//     width: 100%;
//     margin: 0 auto 2em;
//   }

//   .container {
//     margin: 0 0 0.75em;
//     position: relative;
//   }

//   .wrap {
//     display: flex;
//     position: relative;
//     flex-direction: column-reverse;
//   }

//   .header {
//     position: absolute;
//     right: 5em;
//     top: 0.75em;
//     z-index: 50;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 13px;
//   }
// }

// @media (max-width: 541px) {
//   .outer {
//     width: 100%;
//     margin: 0 auto 2em;
//   }

//   .container {
//     margin: 0 0 0.75em;
//   }

//   .wrap {
//     display: flex;
//     flex-direction: column-reverse;
//     margin: 0 auto;
//   }

//   .header {
//     width: 95%;
//     display: flex;
//     justify-content: space-around;
//     margin: 7px auto 0;
//     position: relative;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 0;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 12px;
//   }
// }

// @media (max-width: 415px) {
//   .outer {
//     width: 100%;
//   }

//   .container {
//     margin-bottom: 1em;
//   }

//   .wrap {
//     display: flex;
//     flex-direction: column-reverse;
//   }

//   .header {
//     width: 95%;
//     display: flex;
//     justify-content: space-around;
//     margin: 7px auto 0;
//     position: relative;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 0;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 12px;
//   }
// }

// @media (max-width: 376px) {
//   .outer {
//     width: 100%;
//   }

//   .container {
//     margin-bottom: 1em;
//   }

//   .wrap {
//     display: flex;
//     flex-direction: column-reverse;
//     margin: 0;
//   }

//   .header {
//     width: 95%;
//     display: flex;
//     justify-content: space-around;
//     margin: 7px auto 0;
//     position: relative;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 0;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 12px;
//   }
// }

// @media (max-width: 361px) {
//   .outer {
//     width: 100%;
//   }

//   .container {
//     padding: 0;
//     margin-bottom: 1em;
//   }

//   .wrap {
//     display: flex;
//     flex-direction: column-reverse;
//     margin: 0 8px;
//   }

//   .header {
//     width: 95%;
//     display: flex;
//     justify-content: space-around;
//     margin: 7px auto 0;
//     position: relative;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 0;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 12px;
//   }
// }

// @media (max-width: 321px) {
//   .outer {
//     width: 100%;
//   }

//   .container {
//     padding: 0;
//   }

//   .wrap {
//     display: flex;
//     flex-direction: column-reverse;
//     margin: 0 0px;
//   }

//   .header {
//     width: 95%;
//     display: flex;
//     justify-content: space-around;
//     margin: 7px auto 0;
//     position: relative;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 0;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     font-size: 12px;
//   }
// }

// @media (max-width: 281px) {
//   .outer {
//     width: 100%;
//     margin: 0 auto 2em;
//   }

//   .wrap {
//     display: flex;
//     margin: 0;
//     flex-direction: column-reverse;
//   }

//   .header {
//     width: fit-content;
//     margin: 0 auto 0;
//     position: relative;
//     right: 0;
//     top: 5px;
//     bottom: 0;
//     z-index: 0;
//   }

//   .legend {
//     z-index: 20;
//     left: 0.75em;
//     top: 0.75em;
//     font-size: 10px;
//   }
// }
