.stakeTokensWrap {
  // border: 1px solid green;
  margin-bottom: 3.5rem;
}

.staking {
  // border: 1px solid red;
  margin-top: 3rem;
}

.metricList {
  // border: 1px solid blue;
  margin-top: 3rem;

  & > :not(:last-child) {
    margin-bottom: 2rem;
  }
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 769px) {
  .staking {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    margin-top: 2rem;
  }

  .metricList {
    margin-top: initial;
  }
}
