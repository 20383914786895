// $font-stack: -apple-system, system-ui, 'Segoe UI', Roboto, Helvetica, Arial,

$primary-dark: #383a44;
$secondary-dark: #2d3137;

$carbon--light-1: #8f8f8f;
$carbon--light-2: #707070;
$carbon--light-3: #525252;

$success--primary: hsl(164, 96%, 36%);
$success--light-1: hsl(164, 53%, 61%);
$success--light-2: hsl(164, 53%, 55%);
$success--light-3: hsl(164, 56%, 49%);
$success--dark-1: hsl(164, 96%, 21%);
$success--dark-2: hsl(164, 97%, 14%);
$success--dark-3: hsl(165, 100%, 7%);
$success--dark-4: #01140f;

$warning--primary: hsl(44, 96%, 41%);
$warning--light-1: hsl(44, 66%, 70%);
$warning--light-2: hsl(44, 66%, 58%);
$warning--light-3: hsl(44, 76%, 47%);
$warning--dark-1: hsl(45, 97%, 28%);
$warning--dark-2: hsl(44, 96%, 20%);
$warning--dark-3: hsl(44, 97%, 12%);
$warning--dark-4: #281e01;
$warning--dark-5: #140f01;

$alert--primary: #c2005a;
$alert--light-1: hsl(332, 60%, 63%);
$alert--light-2: hsl(331, 60%, 56%);
$alert--light-3: hsl(332, 61%, 50%);
$alert--dark-1: hsl(332, 100%, 23%);
$alert--dark-2: hsl(332, 100%, 15%);
$alert--dark-3: hsl(332, 100%, 11%);
$alert--dark-4: #290013;
$alert--dark-5: #14000a;

$magenta--primary: #d556f5;
$magenta--light-1: hsl(288, 89%, 79%);
$magenta--light-2: hsl(287, 89%, 75%);
$magenta--light-3: hsl(287, 89%, 72%);
$magenta--dark-1: hsl(288, 48%, 39%);
$magenta--dark-2: hsl(287, 48%, 26%);
$magenta--dark-3: hsl(288, 49%, 19%);
$magenta--dark-4: #1f0227;

$orchid--primary: hsl(277, 91%, 65%);
$orchid--light-1: hsl(277, 91%, 82%);
$orchid--light-2: hsl(278, 91%, 75%);
$orchid--light-3: hsl(278, 90%, 72%);
$orchid--dark-1: hsl(278, 50%, 39%);
$orchid--dark-2: hsl(278, 50%, 26%);
$orchid--dark-3: hsl(276, 61%, 13%);
$orchid--dark-4: #220c31;

$violet--primary: hsl(264, 79%, 63%);
$violet--light-1: hsl(264, 79%, 82%);
$violet--light-2: hsl(264, 79%, 74%);
$violet--light-3: hsl(264, 79%, 70%);
$violet--dark-1: hsl(264, 46%, 44%);
$violet--dark-2: hsl(264, 46%, 31%);
$violet--dark-3: hsl(264, 47%, 19%);
$violet--dark-4: hsl(263, 45%, 12%);
$violet--dark-5: hsl(263, 45%, 9%);

$blurple--primary: hsl(253, 59%, 45%);
$blurple--light-1: hsl(253, 48%, 72%);
$blurple--light-2: hsl(253, 59%, 65%);
$blurple--light-3: hsl(253, 47%, 55%);
$blurple--dark-1: hsl(253, 59%, 35%);
$blurple--dark-2: hsl(253, 59%, 22%);
$blurple--dark-3: hsl(253, 60%, 16%);
$blurple--dark-4: #130b2d;

$indigo--primary: hsl(246, 53%, 41%);
$indigo--light-1: hsl(246, 36%, 64%);
$indigo--light-2: hsl(246, 36%, 58%);
$indigo--light-3: hsl(245, 36%, 53%);
$indigo--dark-1: hsl(246, 53%, 33%);
$indigo--dark-2: hsl(246, 53%, 24%);
$indigo--dark-3: hsl(247, 54%, 16%);
$indigo--dark-4: #120e2f;

$blue--primary: #071422;
$blue--border: #3382d7;
