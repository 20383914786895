.numberFormWrap {
  // border: 1px solid pink;
  padding-top: 3.5rem;
  // height: 234px; // !!!
  height: 259px;
}

.numberInputWrap {
  // border: 1px solid yellow;
  width: 86%;
  margin: 0 auto 1.25rem auto;
}

.button {
  width: 86%;
  margin: 0 auto;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
