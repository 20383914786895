.buttonWrap {
  position: fixed;
  bottom: 1.25rem;
  left: 1.25rem;
  display: grid;
  place-items: center;
  border: none;
  border: 1px solid #4c2fb6;
  border-radius: 50%;
  box-shadow: 0;
  background: #8e40dd;
  width: 3.5rem;
  height: 3.5rem;
  padding-top: 3px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out;
  -webkit-transition: box-shadow 0.3s ease-out;
  -moz-transition: box-shadow 0.3s ease-out;
  -o-transition: box-shadow 0.3s ease-out;
  z-index: 4;

  &:hover {
    box-shadow: 0 0 8px #de65fc;
    opacity: 1;
  }
}

.hide {
  display: none;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

// Tablets
@media (min-width: 501px) {
  .buttonWrap {
    width: 3.75rem;
    height: 3.75rem;
    padding-top: 5px;
  }
}
