.inputButton {
  height: 33px;
  border: 1px solid #575757;
  border-radius: 7px;
  background-color: #202020;
  padding: 0 0.5rem;
  font-family: archialight;
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;

  &:disabled {
    color: #d0d0d0c0;
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.active {
  border: 1px solid #fff;
}

@media (min-width: 321px) {
  .inputButton {
    padding: 0 0.75rem;
  }
}
