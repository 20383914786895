@import '../../../styles/scss/config';

.layoutWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
}

.banner {
  background-image: linear-gradient(
    to right top,
    #9258be,
    #834fb3,
    #7346a8,
    #643d9e,
    #553493
  );
  padding: 1rem;
  margin: 0 auto;
  color: #fff;
  font-style: italic;
  font-weight: 600;
  text-align: center;
}

.warningBanner {
  background: $alert--primary;
  padding: 1em 0 1em;
  font-family: archiabold;
  color: #fff !important;
  font-weight: 300;
  text-align: center;
}

.warningBannerText {
  font-size: 1rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.2rem;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  z-index: 4;
}

.overlaySpinner {
  margin-top: 22rem;
  margin-bottom: 1rem;
}

// .childContainer {
//   max-width: 1500px;
//   margin: 3rem auto auto auto;
//   padding: 0 2rem 7rem 2rem;
// }

// @media (max-width: 1000px) {
//   .childContainer {
//     padding-bottom: calc(10rem + 70px);
//   }
// }

// @media (max-width: 768px) {
//   .childContainer {
//     margin: 0;
//     padding: 0;
//   }
//   .warningBannerText {
//     font-size: 0.9rem;
//   }

//   .childContainer {
//     padding-left: 1.5rem;
//     padding-right: 1.5rem;
//   }
// }

// @media (max-width: 541px) {
//   .childContainer {
//     margin: 0;
//     padding: 0;
//     padding-left: 0.25rem;
//     padding-right: 0.25rem;
//   }
// }
