@import 'config';

@font-face {
  font-family: 'archiathin';
  src: url('../../assets/fonts/archia/archia-thin-webfont.eot');
  src: url('../../assets/fonts/archia/archia-thin-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/archia/archia-thin-webfont.woff2') format('woff2'),
    url('../../assets/fonts/archia/archia-thin-webfont.woff') format('woff'),
    url('../../assets/fonts/archia/archia-thin-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'archialight';
  src: url('../../assets/fonts/archia/archia-light-webfont.eot');
  src: url('../../assets/fonts/archia/archia-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/archia/archia-light-webfont.woff2') format('woff2'),
    url('../../assets/fonts/archia/archia-light-webfont.woff') format('woff'),
    url('../../assets/fonts/archia/archia-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'archiaregular';
  src: url('../../assets/fonts/archia/archia-regular-webfont.eot');
  src: url('../../assets/fonts/archia/archia-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/archia/archia-regular-webfont.woff2')
      format('woff2'),
    url('../../assets/fonts/archia/archia-regular-webfont.woff') format('woff'),
    url('../../assets/fonts/archia/archia-regular-webfont.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'archiamedium';
  src: url('../../assets/fonts/archia/archia-medium-webfont.eot');
  src: url('../../assets/fonts/archia/archia-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/archia/archia-medium-webfont.woff2') format('woff2'),
    url('../../assets/fonts/archia/archia-medium-webfont.woff') format('woff'),
    url('../../assets/fonts/archia/archia-medium-webfont.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'archiabold';
  src: url('../../assets/fonts/archia/archia-bold-webfont.eot');
  src: url('../../assets/fonts/archia/archia-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/archia/archia-bold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/archia/archia-bold-webfont.woff') format('woff'),
    url('../../assets/fonts/archia/archia-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #1a1a1a;
  font-family: archiamedium;
  font-size: 16px;
  color: #d0d0d0;
  letter-spacing: 0.5px;
  line-height: 1.5;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.3em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.8em;
}
h6 {
  font-size: 0.7em;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: 1rem;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

// HIDES ARROWS FROM INPUT TYPE=NUMBER
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// @media (max-width: 1100px) {
//   body {
//     background-position: center bottom;
//   }
// }
//
// @media only screen and (max-width: 768px) {
//   body {
//     background-position: 50% 100%;
//   }
// }
//
// @media only screen and (min-height: 1366px) {
//   body {
//     background-position: 50% -25%;
//   }
// }

.bn-notify-notifications {
  display: block;
}

/* Rechart */
.recharts-cartesian-axis-tick:first-child,
.recharts-cartesian-axis-tick:last-child {
  display: none;
}

address {
  color: #8e40dd;
  text-decoration: underline;
}
