@import '../../../../styles/scss/config';

.textContainer {
  font-family: 'archiathin';
  margin-top: 2.5em;
  height: fit-content;
}

.wrapper {
  margin: 2em auto 0;
}

.augmented {
  --aug-border-all: 2px;
  background: #141414;
  --aug-border-bg: linear-gradient(
    to left top,
    #d556f5,
    #c450ef,
    #b24ae9,
    #a045e3,
    #8e40dd,
    #823ed9,
    #763dd5,
    #693bd1,
    #613ccd,
    #593cca,
    #503dc6,
    #483dc2
  );
  --aug-t-extend1: 50%;
  --aug-b-extend1: 50%;
  padding: 3em;
}

.emphasize {
  font-family: archiabold;
}

.disclaimerHeader {
  font-size: 1.5rem;
  text-align: center;
}

.textScroll {
  height: 100%;
}

.link {
  border-bottom: 1px solid white;
}

.disclaimerWrapper {
  height: fit-content;
  margin: 11% auto;
  width: fit-content;
}

.disclaimerContainer {
  height: 60vh;
  display: grid;
  width: 40vw;
  place-items: center;
  --aug-border-all: 2px;
  background-color: #170821;
  --aug-border-bg: hsl(277, 91%, 82%);
  padding: 2em;
  --aug-b-extend1: 150px;
  --aug-t-extend1: 150px;
  --aug-l-extend1: 150px;
  --aug-r-extend1: 150px;
  --aug-b: 5px;
  --aug-t: 5px;
  --aug-l: 5px;
  --aug-r: 5px;
}

.legalHeader {
  font-family: 'archiaregular';
  padding: 2vh 0 0;
}

.accHeader {
  margin: 0 auto;
  padding: 0.5em 1em;
  height: 10vh;
  display: flex;
  align-items: center;
  width: 95%;
}

.accordionItem {
  --aug-border-all: 2px;
  --aug-bl: 15px;
  --aug-tl: 15px;
  --aug-br: 15px;
  --aug-tr: 15px;
  background: #141414;
  --aug-border-bg: linear-gradient(
    to left top,
    #d556f5,
    #c450ef,
    #b24ae9,
    #a045e3,
    #8e40dd,
    #823ed9,
    #763dd5,
    #693bd1,
    #613ccd,
    #593cca,
    #503dc6,
    #483dc2
  );
}

.accordionInner {
  padding: 1em;
  margin: 1vh auto 3vh;
  width: 95%;
}

.emphasizedText {
  font-size: 1.1rem;
  margin: 0;
  font-family: archiaregular;
  padding: 1em;
  width: 85%;
  text-align: center;
}

.legalOrdered {
  list-style-position: inside;
  margin: 0;
  padding: 0;
  & > li > h2 {
    display: inline-block;
  }
}

.legalList {
  margin: 0.7rem auto;
  list-style: circle !important;
  list-style-type: circle;
  list-style-position: inside !important;
}

.paragraph {
  padding: 1vh 0 0;
}

@media (max-width: 450px) {
  .wrapper {
    margin: 0 auto;
    padding-bottom: 1.5rem;
    & > h1 {
      font-size: 1.3em;
      text-align: center;
      font-family: archiamedium;
    }
  }

  .textContainer {
    padding: 0 0.5em;
  }

  .accHeader {
    font-size: 1em;
    padding: 1em 1em;
    height: fit-content;
    width: 80%;
    text-align: center;
  }

  .augmented {
    padding: 2.25em 0.5em;
    margin-bottom: 7em !important;
  }

  .disclaimerContainer {
    margin: 2em auto;
  }

  .legalHeader {
    font-family: 'archiabold';
    padding: 2em 0 0.5em;
    font-size: 1em;
  }

  .paragraph {
    font-size: 0.8em;
  }

  .legalList {
    font-size: 0.8em;
  }
}
