.panelWrap {
  // border: 1px solid pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 302px;
}

.panelContainer {
  padding: 1rem;
}

.metricValue {
  padding-left: 4px;
  font-size: 1.65rem;
  font-family: archialight;
  color: #cb6df7; // heliotrope
  text-shadow: 1px 1px 8px rgb(136, 70, 118), 1px 1px 8px rgba(192, 54, 156, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.positiveGreen {
  color: #04b485;
  text-shadow: none;
}

.negativeRed {
  color: #c2005a;
  text-shadow: none;
}
