.panelContainer {
  // border: 1px solid blue;
  padding: 1.25rem 0rem;
}

.panelRow {
  // border: 1px solid green;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 2.5rem;

  // Applies to row's children
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.panelRow:last-child {
  margin-bottom: initial;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 881px) {
  .panelContainer {
    padding: 1.25rem 1.75rem;
  }

  .panelRow {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    margin-bottom: 2.5rem;
  }
}
