.navWrap {
  // border: 1px solid yellow;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.leftNav {
  // border: 1px solid green;
  display: none;
  justify-content: space-between;
  width: 250px;
}

.navLinkWrap {
  font-family: archialight;
  letter-spacing: 1px;
}

.activeNavLink {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 3px;
    background: hsla(288, 89%, 65%, 1);

    background: linear-gradient(
      315deg,
      hsla(288, 89%, 65%, 1) 0%,
      hsla(246, 53%, 41%, 1) 100%
    );

    background: -moz-linear-gradient(
      315deg,
      hsla(288, 89%, 65%, 1) 0%,
      hsla(246, 53%, 41%, 1) 100%
    );

    background: -webkit-linear-gradient(
      315deg,
      hsla(288, 89%, 65%, 1) 0%,
      hsla(246, 53%, 41%, 1) 100%
    );
  }
}

.rightNav {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 1.5rem;
  }
}

.iconNavLinkWrap {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.2);
  }

  &.activeNavLink {
    transform: scale(1.2);
  }
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 1201px) {
  .navWrap {
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 3rem;
  }

  .leftNav {
    display: flex;
  }
}
