@import '../../../../styles/scss/config';

.panelWrap {
  width: fit-content;
  font-family: archialight;
  color: #fff;
}

.panelContainer {
  // border: 1px solid blue;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.3rem 0.75rem;
}

.addressBox {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.greenDot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: $success--primary;
}

.accountAddress {
  flex-grow: 1;
  text-align: center;
}

.balanceBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333;
  border-radius: 7px;
  background-color: #111;
  margin-left: 0.75rem;
  padding: 0.25rem 1rem;
  line-height: initial;
  text-align: center;
}
