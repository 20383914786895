.mainMetricsSection {
  // border: 1px solid green;
  display: grid;
  grid-gap: 2rem;
  // margin-bottom: 6rem;
}

.panelContainer {
  position: relative;
  display: grid;
  place-items: center;
  padding: 1.75rem 1rem;
}

.metricWrap {
  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
}

.metricTitle {
  font-size: 19px;
}

.metricValue {
  padding: 0.25rem 0rem;
  font-size: 27px;
}

.tourToggleContainer {
  display: flex;
  justify-content: flex-end;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 501px) {
  .metricTitle {
    font-size: 1.2rem;
  }

  .metricValue {
    font-size: 2.5rem;
  }
}

@media (min-width: 1201px) {
  .mainMetricsSection {
    grid-gap: 3rem;
  }
}
