@import '../styles//scss/config';

.pageContentWrap {
  position: relative;
  border: 1px solid transparent; // Don't remove transparent border!
  // border: 1px solid yellow;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.reconnectButton {
  border: none;
  border-bottom: 1px solid #d0d0d0;
  background-color: transparent;
  padding-bottom: 1px;
  font-family: archialight;
  font-size: 0.875rem; // 14px
  color: #d0d0d0;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid $success--primary;
    color: $success--primary;
  }
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 769px) {
  .pageContentWrap {
    padding: 0 2rem;
  }
}
