.header {
  // border: 1px solid green;
  display: flex; // Required for tour box to show for some reason
  margin-bottom: 2rem;
}

.panels {
  // border: 1px solid orange;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :last-child {
    margin-top: 2rem;
  }
}

.metricWrap {
  // border: 1px solid blue;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metricTitle {
  // border: 1px solid yellow;
  font-size: 18px;
  text-align: center;
  line-height: 1.3;
}

.metricValue {
  // border: 1px solid green;
  font-size: 22px;
}

.button {
  height: initial;
  width: 190px;
  padding: 0.8rem 0.6rem;
}

.statsRow {
  // Applies to row's children
  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.textLink {
  color: #8e40dd;

  &:hover {
    text-decoration: underline;
  }
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .panelTitle {
    font-size: 25px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 881px) {
  .panels {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    & > :last-child {
      margin-top: initial;
    }
  }

  .statsRow {
    // Applies to row's children
    & > :not(:last-child) {
      margin-bottom: initial;
    }
  }
}

@media (min-width: 1051px) {
  .panels {
    grid-gap: 3rem;
  }

  .metricTitle {
    font-size: 1.25rem;
  }

  .metricValue {
    font-size: 24px;
  }
}
