.toggleWrap {
  position: relative;
  display: grid;
  place-items: center;
  width: 220px;
  height: 48px;
  margin: 3rem auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 7px;

    background: linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -webkit-linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -moz-linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -o-linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -ms-linear-gradient(to left, #483dc2, #d556f5) border-box;

    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask: -webkit-linear-gradient(#fff 0 0) padding-box,
      -webkit-linear-gradient(#fff 0 0);
    -moz-mask: -moz-linear-gradient(#fff 0 0) padding-box,
      -moz-linear-gradient(#fff 0 0);
    -o-mask: -o-linear-gradient(#fff 0 0) padding-box,
      -o-linear-gradient(#fff 0 0);
    -ms-mask: -ms-linear-gradient(#fff 0 0) padding-box,
      -ms-linear-gradient(#fff 0 0);

    mask-composite: exclude;
    // -webkit-mask-composite: destination-out;
    z-index: -1;
  }
}

.navLinkList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid green;
  width: 94%;
  height: 80%;
  font-family: archialight;
  font-size: 14px;
  color: hsl(0, 0%, 41%);
  text-transform: lowercase;
}

.navLink {
  display: grid;
  place-items: center;
  width: 100px;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #575757;
  text-align: center;
  cursor: pointer;
}

.activeNavLink {
  border: 1px solid #575757;
  background-color: #272727;
  color: #f1f1f6;
}
