.text {
  font-size: 1.75rem;
  font-weight: 200;
  color: #d0d0d0;
  font-family: archiaregular;
  text-shadow: none;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

@media (max-width: 541px) {
  .text {
    font-size: 1.5rem;
    margin: 1em 0em 0.25em;
  }
}

@media (max-width: 321px) {
  .text {
    font-size: 1.35rem;
    margin: 1em 0.5em 0.25em;
  }
}

@media (max-width: 281px) {
  .text {
    font-size: 1.25rem;
    margin: 1em 0.15em 0.25em;
  }
}
