@import '../../../../styles/scss/config';

.toolTipWrap {
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid hsl(240, 6%, 40%);
  box-shadow: rgba(173, 173, 173, 0.534) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  border-radius: 50%;
  margin: 0 0.4375rem 0.1rem 0.4375rem; // 7px if 1rem = 16px
  cursor: pointer;
}

.infoWrap {
  max-width: 250px;
  border: 1px solid #333333;
  // box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px,
  //   rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  border-radius: 7px;
  background-color: #202020;
  margin: 0rem 1rem;
  padding: 0.7rem;
  text-align: left;
  z-index: -1;

  .infoTitle {
    margin-bottom: 0.25rem;
    color: hsl(0, 0%, 81%);
  }

  .infoBody {
    font-family: archiathin;
    font-size: 0.9rem;
    color: hsl(0, 0%, 65%);
  }
}
