.linkButton {
  display: grid;
  place-items: center;
  height: 34px;
  padding: 0 1.1rem;
  border: 1px solid #ff8902;
  border-radius: 7px;
  background-color: #4e2a01;
  font-family: archiamedium;
  font-size: 0.85rem;
  color: #d0d0d0;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .linkButton {
    font-size: 1rem;
  }
}
