.brandWrap {
  // border: 1px solid yellow;
  display: inline-flex;
  align-items: center;
  height: 45px;
}

.brandText {
  margin-left: 0.75rem;
  font-family: archiaregular;
  font-size: 1.45rem;
  color: #d0d0d0;
  letter-spacing: 2px;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .brandText {
    font-size: 1.875rem;
    margin-left: 1rem;
  }
}
