@import '../../../../styles/scss/config';

.button {
  display: block;
  width: fit-content;
  border: 1px solid #d0d0d0;
  border-radius: 7px;
  background-color: #1a1a1a;
  font-family: archialight;
  color: #d0d0d0;
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;

  &:disabled {
    color: #d0d0d0c5;
    opacity: 0.55;
    cursor: not-allowed;
  }
}

// COLORS

.purple {
  border-color: #663da4;
  background-color: #231638;
}

.blue {
  border-color: #3382d7;
  background-color: #0a1e33;
}

.green {
  border-color: #04b485;
  background-color: #022219;
}

.gold {
  border-color: #cd9704;
  background-color: #3c2c01;
}

.orange {
  border-color: #ff8902;
  background-color: #4e2a01;
}

.red {
  border-color: #c2005a;
  background-color: #4b0626;
}

// .gradient {
//   position: relative;
//   border: none;

//   &:before {
//     content: '';
//     position: absolute;
//     top: -2.5%;
//     left: -0.5%;
//     width: 101%;
//     height: 105%;
//     border-radius: 7px;
//     background-image: linear-gradient(45deg, #d556f5, #483dc2);
//     background-image: -webkit-linear-gradient(45deg, #d556f5, #483dc2);
//     z-index: -1;
//   }
// }

.gradient {
  position: relative;
  border: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 7px;

    background: linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -webkit-linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -moz-linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -o-linear-gradient(to left, #483dc2, #d556f5) border-box;
    background: -ms-linear-gradient(to left, #483dc2, #d556f5) border-box;

    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask: -webkit-linear-gradient(#fff 0 0) padding-box,
      -webkit-linear-gradient(#fff 0 0);
    -moz-mask: -moz-linear-gradient(#fff 0 0) padding-box,
      -moz-linear-gradient(#fff 0 0);
    -o-mask: -o-linear-gradient(#fff 0 0) padding-box,
      -o-linear-gradient(#fff 0 0);
    -ms-mask: -ms-linear-gradient(#fff 0 0) padding-box,
      -ms-linear-gradient(#fff 0 0);

    mask-composite: exclude;
    // -webkit-mask-composite: destination-out;
  }
}

// SIZES

.small {
  height: 36px;
  padding: 0 0.6rem;
  font-size: 14px;
}

.medium {
  height: 48px;
  padding: 0 1.1rem;
  font-size: 1rem;
}

.large {
  height: 60px;
  padding: 0 2rem;
  font-size: 18px;
}
