.panelHeader {
  padding-top: 3.2rem;
}

.numberFormWrap {
  height: 280px;
}

.claimAndExitBtn {
  width: 86%;
  margin: 0 auto;
  margin-top: 1.2rem;
}
