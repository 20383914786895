.selectStablecoinWrap {
  display: inline-block;
}

.panelWrap {
  display: flex;
  align-items: center;
  height: 62px;
}

.panelContainer {
  // border: 1px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;

  button {
    margin-right: 0.95rem;
  }

  button:last-child {
    margin-right: 0rem;
  }
}

.optionButton {
  border: none;
  border-radius: 100%;
  padding: 0;
  background: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: inherit;
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  &.activeStyle {
    transform: scale(1.175);
    border: 2px solid #fff;
  }
}
