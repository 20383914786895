.panelHeader {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  padding-top: 3.2rem;
  padding-bottom: 2.5rem;
}

.numberFormWrap {
  // border: 1px solid red;
  padding-top: 2rem;
}

.earlyWithdrawalNotice {
  // border: 1px solid green;
  width: 75%;
  margin: 1rem auto 0.75rem auto;
  font-family: 'archiathin';
  font-size: 13px;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 769px) {
  .togglePanelWrap {
    width: 400px;
  }
}
