.panelWrap {
  position: relative;
  width: 100%;
  height: fit-content;
  border: 2px solid #333333;
  border-radius: 7px;
  background: #272727;
  overflow: hidden;
}

.panelWrap > span:after,
.animate > span > span {
  animation: load 1s linear infinite;
}

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 100px;
  }
}

.panelContainer {
  // Can insert default panelContainer CSS here
}

.cornerComponentWrap {
  position: absolute;
  top: 0.65rem;
  right: 0.65rem;
}
