.mainContent {
  // border: 1px solid green;
  padding: 0 2rem;
  // overflow: visible; // Not sure why this was added before; keep commented out for now
}

.container {
  --aug-tr: 20px;
  --aug-br: 20px;
  --aug-tl: 20px;
  --aug-bl: 20px;
  --aug-border-all: 3px;
  --aug-border-bg: radial-gradient(
    circle,
    #d556f5,
    #c450ef,
    #b24ae9,
    #a045e3,
    #8e40dd,
    #823ed9,
    #763dd5,
    #693bd1,
    #613ccd,
    #593cca,
    #503dc6,
    #483dc2
  );
  max-width: 900px;
  margin: 2rem auto;
  padding: 1.5rem;
}

.textBox {
  margin: 0 auto;

  h1 {
    margin-bottom: 1.5rem;
    font-family: archiabold;
    font-size: 1.5rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 1rem;
    font-family: archiathin;
    color: rgba(256, 256, 256, 0.7);
  }
}

.btnGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: 60%;
  margin: 1rem auto 0rem auto;
  // overflow: visible; // Not sure why this was added before; keep commented out for now, maybe for gradient?

  button:first-child {
    margin-bottom: 0.75rem;
  }
}

.docsIcon {
  height: 40px;
  background-size: 60%;
  background-position: center;
  width: 40px;
  background-image: url('../../../assets/gitbook-icon.svg');
  background-repeat: no-repeat;
}

.iconAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

// Tablets
@media (min-width: 501px) {
  .mainContent {
    padding: 0 3rem;
  }

  .container {
    max-width: 900px;
    margin: 5rem auto;
    padding: 2rem;
  }

  .textBox {
    h1 {
      margin-bottom: 1.75rem;
      font-size: 3rem;
      font-weight: 900;
    }

    h2 {
      font-size: 1.5rem;
      color: rgba(256, 256, 256, 0.7);
    }
  }

  .btnGroup {
    // border: 1px solid green;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2em auto;

    button:first-child {
      margin-bottom: initial;
      margin-right: 3rem;
    }
  }
}
