@import '../../../../styles/scss/config';

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  height: 40px;
  padding: 0 1rem;
  font-size: 0.8rem;
  font-family: archialight;
  border-color: #666;
  border-radius: 8px;
  background-color: rgba(28, 28, 28, 0.65);
  color: #d0d0d0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  border: 1px solid;
  letter-spacing: 1.5px;
  text-align: center;
  outline: none;
  cursor: pointer;

  &:disabled {
    border-color: #666;
    // background-color: rgba(28, 28, 28, 0.65);
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.primary {
  border-color: #d0d0d0;
}

.magenta {
  border-color: hsl(288, 89%, 65%);
  background-color: $magenta--dark-4;
}

.violet {
  border-color: hsl(264, 79%, 63%);
  background-color: $violet--dark-4;
}

.orchid {
  border-color: hsl(277, 91%, 65%);
  background-color: $orchid--dark-4;
}

.blurple {
  border-color: hsl(253, 59%, 45%);
  background-color: $blurple--dark-4;
}

.indigo {
  border-color: hsl(246, 53%, 41%);
  background-color: $indigo--dark-4;
}

.red {
  border-color: hsl(332, 100%, 38%);
  background-color: $alert--dark-4;
}

.green {
  border-color: hsl(164, 96%, 36%);
  background-color: $success--dark-4;
}

.gold {
  border-color: hsl(44, 96%, 41%);
  background-color: $warning--dark-4;
}

.blue {
  border-color: $blue--border;
  background-color: $blue--primary;
}

// Specific Sizing
.strategiesMobile {
  width: 100%;
  margin: 0 auto;
}

.landing {
  width: 160px;
  height: 50px;
  font-size: 1rem;
}

.form {
  width: 343px;
  border-radius: 7px;
  height: 50px;
  // border: 1px solid red;
  font-size: 1rem;
  margin: 0 auto;
}

.small {
  width: 160px;
  font-size: 0.8rem;
  margin: 10px auto;
}

.extraSmall {
  width: 140px;
  border-radius: 7px;
  font-size: 0.8rem;
  margin: 0 auto;
}

.extraExtraSmall {
  width: 90px;
  border-radius: 7px;
  font-size: 0.8rem;
  display: grid;
  place-items: center;
  padding: 0;
  margin: 0 10px;
}

@media (max-width: 1025px) {
  .form {
    width: 65%;
  }
}

@media (max-width: 769px) {
  .form {
    width: 85%;
  }
}

@media (max-width: 541px) {
  .form {
    width: 60%;
  }
}

@media (max-width: 415px) {
  .form {
    width: 75%;
  }
}

@media (max-width: 380px) {
  .form {
    width: 75%;
    height: 45px;
    font-family: archialight;
  }

  .landing {
    margin: 5px 0;
    font-size: 12px;
  }
}

@media (max-width: 361px) {
  .form {
    height: 45px;
    font-family: archialight;
    width: 80%;
  }

  .landing {
    margin: 5px 0;
    font-size: 12px;
  }
}
