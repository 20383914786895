.toastListWrap {
  position: fixed;
  font-size: 0.875rem; // 14px
  z-index: 3;
}

// Positions

.topRight {
  top: 1rem;
  right: 1rem;
  animation: enterFromRight 0.7s;
}

.bottomRight {
  bottom: 1rem;
  right: 1rem;
  animation: enterFromRight 0.7s;
}

.topLeft {
  top: 1rem;
  left: 1rem;
  animation: enterFromLeft 0.7s;
}

.bottomLeft {
  bottom: 1rem;
  left: 1rem;
  animation: enterFromLeft 0.7s;
}

.toastWrap {
  position: relative;
  display: flex;
  border-left: 10px solid transparent;
  border-radius: 7px;
  box-shadow: 0 0 10px #999;
  background: #202020;
  min-width: 375px; // 375px , fit-content
  height: 100px;
  margin-bottom: 1.5rem;
  padding-right: 1.5rem;
  opacity: 0.9;
  font-family: 'archiathin';
  pointer-events: auto;
  transition: 0.3s ease;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer;
  }
}

// Transitions

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 14px;
}

.toastIcon {
  display: grid;
  place-items: center;
  padding: 0 0.75rem;
}

.toastContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
}

.toastTitle {
  font-family: 'archiabold';
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toastMessage {
  overflow: hidden;
  text-overflow: ellipsis;
}
