.metricColumnWrap {
  border: 2px solid hsl(0, 0%, 10%);
  // border: 2px solid blue;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  height: 100%;
  scroll-margin-bottom: 5rem;
}

.title {
  // border: 1px solid green;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.metricList {
  // border: 1px solid green;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 3rem;
  width: 100%;
  height: 100%;

  & > div {
    height: 100%;
  }
}

// .metricList {
//   // border: 1px solid green;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   flex-grow: 1;

//   & > div {
//     flex-grow: 1;
//   }
// }

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 769px) {
  .metricList {
    grid-gap: 2rem;
  }
}

@media (min-width: 1201px) {
  .metricList {
    grid-gap: 3rem;
  }
}
