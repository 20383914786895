// @import '../../../styles/scss/config';

.springBtn {
  position: relative;
  display: inline-block;
  border: none;
  height: 30px;
  width: 50px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 14px;
  font-family: archialight;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  font-weight: 800;
  background-color: #161618;
  color: hsl(0, 0%, 41%);
  border: 1px solid hsl(240, 6%, 25%);
  text-transform: lowercase;
  cursor: pointer;
  pointer: cursor;

  &:focus {
    outline: none;
  }
}

.active {
  color: hsl(0, 0%, 81%);
  border: 1px solid hsl(240, 6%, 50%);
}

@media (max-width: 321px) {
  .springBtn {
  }
}
