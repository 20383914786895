@import '../../../../styles/scss/config';

.container {
  padding: 0.25em;
  width: fit-content;
  border: 1px solid $warning--light-3;
  border-radius: 7px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  justify-content: space-around;
}

.icon {
  background-image: url('../../../../assets/metamask.svg');
  height: 20px;
  margin: 0 0 0 0.5em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
}

.cta {
  width: fit-content;
  color: #d0d0d0;
  font-family: archialight;
  padding: 0.75em;
  font-size: 1rem;
}

@media (max-width: 415px) {
  .cta {
    width: fit-content;
    padding: 0.75em;
    font-size: 0.7rem;
  }
}
