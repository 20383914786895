.slideshowWrapper {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slideshowContent {
  // border: 1px solid green;
  max-width: 324px;
  padding: 0.25rem 1.25rem;
  font-family: archiathin;
  font-size: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slideshowControl {
  // border: 1px solid yellow;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0 auto;
  font-size: 1rem; /////////
  font-family: archiathin;
}

.slideshowControlItem {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-family: archiathin;
  font-size: 1rem;
  color: #d0d0d0;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.1);
  }
}

.arrowControl {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.4);
  }
}

.active {
  color: #fff;
  font-family: archiamedium;
}

.mockValue {
  font-family: archialight;
  color: #cb6df7; // heliotrope
  text-shadow: 1px 1px 8px rgb(136, 70, 118), 1px 1px 8px rgba(192, 54, 156, 1);
}

.positiveGreen {
  color: #04b485;
  text-shadow: none;
}

.negativeRed {
  color: #c2005a;
  text-shadow: none;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 375px) {
  .slideshowControl {
    width: 80%;
  }
}

@media (min-width: 501px) {
  .slideshowContent {
    font-size: 2rem;
  }

  .slideshowControl {
    width: 275px;
  }
}

@media (min-width: 769px) {
  .slideshowContent {
    font-size: 27px;
  }

  .slideshowControl {
    width: 80%;
  }
}

@media (min-width: 1001px) {
  .slideshowContent {
    font-size: 2rem;
  }
}
