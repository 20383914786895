.formToggleWrap {
  position: absolute;
  top: -25px;
  right: 0;
  left: 0;
  display: flex;
  margin: 0 auto;
  border: 2px solid #333;
  border-radius: 7px 7px 0 0;
  background: #202020;
  z-index: 2;

  .tab {
    position: relative;
    width: 65%;
    margin: 0.5rem;
    padding: 0.5rem 0;
    text-align: center;
    cursor: pointer;
    outline: none;

    .activeOverlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #353535;
      border-radius: 3px;
      background-color: #474747;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05),
        4px 4px 16px 0 rgba(0, 0, 0, 0.1);
    }

    span {
      position: relative;
      font-size: 1rem;
      color: #555555;
      white-space: nowrap;
      transition: ease 100ms color;
      z-index: 1;
    }

    & .activeText {
      color: #f1f1f6;
    }
  }
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 501px) {
  .formToggleWrap {
    width: 70%;
    border-radius: 7px;
  }
}

@media (min-width: 769px) {
  .formToggleWrap {
    width: 100%;
    border-radius: 7px 7px 0 0;
  }
}

@media (min-width: 1001px) {
  .formToggleWrap {
    width: 70%;
    border-radius: 7px;
  }
}
