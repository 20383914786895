.portfolioPageWrap {
  // border: 1px solid red;
  max-width: 900px;
  margin: 0 auto;
}

.growthValue {
  width: 225px;
}

.farmColor {
  border: 1px solid #04b485;
  border-left: 10px solid #04b485;
  background-color: #01241b;
}

.pageTourToggle {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

// Tablets
@media (min-width: 501px) {
  .portfolioPageWrap {
    padding: 0 2rem;
  }
}
