.lpPageHeaderWrap {
  position: relative;
  border: 2px solid #1a1a1a;
  border-radius: 7px;
  margin-bottom: 3rem;

  .sectionHeaderWrap {
    // border: 1px solid yellow;
    max-width: 500px;
  }

  .sectionHeaderTitle {
    font-size: 1.9rem;
    font-weight: bold;
  }
}

.button {
  width: 175px;
  margin: 2rem auto 0 auto;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .button {
    margin: initial;
    margin-top: 1rem;
    margin-left: 8px;
  }
}

@media (min-width: 769px) {
  .lpPageHeaderWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.5rem;
    padding: 0.5rem 0;
  }

  .button {
    margin: initial;
    margin-right: 8px;
  }
}
