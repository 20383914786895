.percentageFormWrap {
  // border: 1px solid red;
  // height: 234px; // !!!
  height: 259px;
  padding-top: 0.75rem;
}

.percentageFormContainer {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0.75rem auto;
  padding: 0.25rem;
}

.percentageInputWrap {
  // border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div:first-child {
    margin-right: 1.5rem;
  }
}

.percentageSliderWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border: 1px solid blue;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.slider {
  width: 90%;
  margin: 0.25rem auto;
}

.button {
  width: 86%;
  height: 65px;
  margin: 0 auto;
  font-size: 15px;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

// Tablets
@media (min-width: 501px) {
  .percentageFormWrap {
    padding-top: 1.75rem;
  }

  .percentageFormContainer {
    flex-direction: row;
    padding: initial;
    width: 86%;
    height: 90px;
  }

  .percentageInputWrap {
    display: block;
    width: initial;

    & > div:first-child {
      margin-right: initial;
    }
  }

  .percentageSliderWrap {
    align-items: center;
    margin-top: initial;
    margin-left: 1rem;
    padding-top: 1rem;
  }

  .slider {
    width: 100%;
    margin: initial;
    margin-bottom: 1.2rem;
  }

  .button {
    height: 75px;
  }
}

@media (min-width: 769px) {
  .percentageFormWrap {
    padding-top: 0.75rem;
  }

  .percentageFormContainer {
    flex-direction: column;
    width: initial;
    height: initial;
    padding: 0.25rem;
  }

  .percentageInputWrap {
    // border: 1px solid yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    & > div:first-child {
      margin-right: 1.5rem;
    }
  }

  .percentageSliderWrap {
    display: flex;
    align-items: initial;
    margin-top: 1rem;
    margin-left: initial;
    padding-top: initial;
  }

  .slider {
    width: 90%;
    margin: 0.25rem auto;
  }

  .button {
    height: 65px;
  }
}

@media (min-width: 1001px) {
  .percentageFormWrap {
    padding-top: 1.75rem;
  }

  .percentageFormContainer {
    flex-direction: row;
    width: 86%;
    height: 90px;
    padding: initial;
  }

  .percentageInputWrap {
    display: block;
    width: initial;

    & > div:first-child {
      margin-right: initial;
    }
  }

  .percentageSliderWrap {
    align-items: center;
    margin-top: initial;
    margin-left: 1rem;
    padding-top: 1rem;
  }

  .slider {
    width: 100%;
    margin: initial;
    margin-bottom: 1.2rem;
  }

  .button {
    height: 75px;
  }
}
