.arrowBoxWrap {
  position: fixed;
  bottom: 1.5rem;
  left: 5.5rem;
  margin-left: 1rem;
  z-index: 4;
  background: #1a1a1a;
}

.arrowBoxContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
  font-family: archiathin;
  font-size: 0.9rem;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 7px;
    background: linear-gradient(to left, #483dc2, #d556f5) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.closeBtn {
  position: absolute;
  top: -7px;
  right: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  background-color: rgb(80, 80, 80);
  color: #d0d0d0;
  transition: 0.3s ease;

  &:hover {
    box-shadow: 0 0 7px #fff;
    opacity: 1;
    cursor: pointer;
  }
}

.arrow {
  position: relative;
  margin-right: 0.5rem;
  padding-top: 2px;
  font-size: 28px;
  animation: animateArrow 800ms infinite;
}

.hide {
  visibility: hidden;
}

@keyframes animateArrow {
  0% {
    right: 0;
  }
  50% {
    right: 10px;
  }
  100% {
    right: 0;
  }
}
