.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #101010;
  width: 100%;
  height: 100%;
  padding-top: 8rem;
  font-family: archialight;
  font-size: 18px;
  // z-index: 6;
  z-index: 4;
}

.container {
  // border: 1px solid red;
  // width: 175px;
  text-align: center;
  // margin-top: 3rem;

  // position: relative;
  // bottom: 1%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  // width: 100%;
  // height: 65%;
}

.balanceWrap {
  // border: 1px solid green;
}

.balanceLabel {
  font-size: 1rem;
  text-align: center;
}

.balanceBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333;
  border-radius: 7px;
  background-color: #111;
  padding: 0.25rem 1rem;
  line-height: initial;
  text-align: center;
}

.navLinkWrap {
  padding: 0.3rem 0;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #8e40dd;
    }
  }

  .activeNavLink {
    color: #8e40dd;
  }
}

.disconnectButton {
  height: 34px;
  font-family: archiamedium;
}

.menuItem {
  width: 50%;
  text-transform: lowercase;
  & > h2 {
    color: rgba(256, 256, 256, 0.75);
  }
}

.internalLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgba(256, 256, 256, 0.75);
}

.footerLink {
  font-family: archialight;
  text-align: center;
  text-transform: lowercase;
  cursor: pointer;
}

// @media (min-width: 1025px) {
//   .wrapper {
//     display: none;
//   }
// }

// @media (max-width: 281px) {
//   .footerLink {
//     font-size: 0.9rem;
//   }
// }

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 769px) {
  .wrapper {
    padding-top: calc(3.5rem + 80px); // Factors in Header
  }
}

// Landscape
@media (max-height: 500px) {
  .wrapper {
    padding-bottom: calc(5rem + 80px); // Factors in Header
    overflow-y: scroll;
  }
}
