.inputField {
  margin-right: 0.2rem;
  border: none;
  padding-left: 0.25rem;
  background-color: transparent;
  font-family: archiabold;
  font-size: 1.2rem;
  color: #d0d0d0;
  text-align: center;
  outline: none;
}

.inputLabel {
  margin-left: 0.15rem;
  font-size: 0.75rem;
  font-family: archiaregular;
  color: #d0d0d0;
  text-align: center;
}
