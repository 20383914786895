.stakingPageWrap {
  position: relative;
  // border: 1px solid red;
  max-width: 750px;
  margin-bottom: 8rem;
  padding-top: 3.5rem;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 501px) {
  .stakingPageWrap {
    margin: 2rem auto;
    margin-bottom: 8rem;
  }
}
