.panelWrap {
  // border: 1px solid pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // max-width: 324px;
}

.panelContainer {
  // border: 1px solid yellow;
  padding: 1.75rem 1rem;
}

.metricWrap {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.metricTitle {
  // border: 1px solid yellow;
  justify-content: center;
  font-size: 19px;
}

.metricValue {
  // border: 1px solid green;
  padding: 0.25rem 0rem;
  font-size: 27px;
}

.rewardsDisplay {
  // max-width: 324px;
  padding: 0rem 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formatToggleWrap {
  // border: 1px solid red;
  display: flex;
  border: 1px solid #333;
  background: #202020;
}

.formatBtn {
  display: grid;
  place-items: center;
  border: 1px solid transparent;
  background-color: transparent;
  width: 32px;
  height: 28px;
  font-size: 13.5px;
  color: #555555;
  cursor: pointer;
}

.activeFormatBtn {
  background-color: #474747;
  color: #f1f1f6;
}

.toolTipWrap {
  margin-bottom: 3px;
}

.positiveGreen {
  color: #04b485;
}

.negativeRed {
  color: #c2005a;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 501px) {
  .metricTitle {
    font-size: 1.2rem;
  }

  .metricValue {
    font-size: 2rem;
  }
}

@media (min-width: 769px) {
  // .panelWrap {
  //   max-width: 324px;
  // }

  .metricTitle {
    font-size: 19px;
  }

  .metricValue {
    font-size: 27px;
  }
}

@media (min-width: 1001px) {
  .metricTitle {
    font-size: 1.2rem;
  }

  .metricValue {
    font-size: 2rem;
  }
}
