.headerWrap {
  position: sticky;
  top: 0;
  background-color: #1a1a1a;
  border-width: 0px;
  border-bottom: 2px solid black !important;
  border-image-slice: 1;
  border-image-source: radial-gradient(
    circle,
    #483dc2,
    #503dc6,
    #593cca,
    #613ccd,
    #693bd1,
    #763dd5,
    #823ed9,
    #8e40dd,
    #a045e3,
    #b24ae9,
    #c450ef,
    #d556f5
  );
  z-index: 5;
}

.headerContainer {
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .headerContainer {
    height: 80px;
    padding: 0 2rem;
  }
}
