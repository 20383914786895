.slider {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  height: 15px;
  border: 1px solid #575757;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.5);
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #292929;
    background-image: url('../../../../assets/slider-orbit.svg');
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #292929;
    background-image: url('../../../../assets/slider-orbit.svg');
    cursor: pointer;
  }
}
