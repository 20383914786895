@import '../../../../../../../styles/scss/config';

.panelHeader {
  padding-top: 3.2rem;
}

.numberFormWrap {
  padding-top: 3.5rem;
  height: 280px;
}

.numberInputWrap {
  width: 86%;
  margin: 0 auto 1.25rem auto;
}

.button {
  width: 86%;
  margin: 0 auto 1.2rem auto;
}

.claimAndExitBtn {
  width: 86%;
  margin: 0 auto;
}

.formBalanceWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94px;
  // border: 1px solid yellow;
  color: #d0d0d0;
  text-align: center;
  margin-bottom: 1rem;

  .formBalanceLabel {
    font-family: archialight;
    font-size: 1.75rem;
    color: #d0d0d0;
  }

  .formBalance {
    display: grid;
    place-items: center;
    padding: 0 2rem;
    // border: 1px solid green;
    font-family: archialight;
    font-size: 2rem;
    color: #cb6df7; // heliotrope
    text-shadow: 1px 1px 8px rgb(136, 70, 118),
      1px 1px 8px rgba(192, 54, 156, 1);

    .notEnabled {
      padding: 0 2rem;
      font-family: archialight;
      font-size: 1.5rem;
      color: #c2005a;
      text-shadow: none;
    }
  }
}
