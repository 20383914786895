.boxWrap {
  position: relative;
  display: flex;
  width: 75px;
  height: 75px;
}

.boxContainer {
  position: absolute;
  top: 2px;
  left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 92%;
  height: 95%;
  // border: 1px solid red;
  border-radius: 15px;
  padding: 0.25rem;
  text-align: center;
}
