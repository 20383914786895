.bannerWrap {
  // position: sticky;
  // position: -webkit-sticky;
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: #883dd3;
  // height: 75px;
  padding: 1.25rem;
  text-align: center;
  z-index: 9;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .bannerWrap {
    height: 80px;
  }
}
