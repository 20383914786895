.claimApyWrap {
  // border: 1px solid green;
  margin-bottom: 3.5rem;
}

.sectionHeaderTitle {
  // border: 1px solid green;
  margin-bottom: 0;
}

.claimApyHeader {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: flex-end;
  align-items: center;
  padding-bottom: 0.95rem;
}

.button {
  height: initial;
  padding: 1rem;
  white-space: nowrap;
}

.claimApyPanels {
  // border: 1px solid yellow;
  display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 5rem;
  grid-gap: 1.5rem;
  margin-top: 1rem;
}

.panelContainer {
  // border: 1px solid yellow;
  padding: 2rem 1.5rem;
}

.metricWrap {
  // border: 1px solid green;
  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
}

.metricTitle {
  font-size: 19px;
}

.metricValue {
  // border: 1px solid green;
  padding: 0.75rem 0rem 0.25rem 0rem;
  font-size: 27px;
  line-height: 0.75;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .claimApyHeader {
    flex-direction: row;
  }

  .claimApyPanels {
    margin-top: initial;
  }

  .metricTitle {
    font-size: 1.2rem;
  }

  .metricValue {
    font-size: 2rem;
  }
}

@media (min-width: 769px) {
  .claimApyPanels {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
}

@media (min-width: 1201px) {
  .claimApyPanels {
    grid-gap: 3rem;
  }
}
