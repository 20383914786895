.panelWrap {
  // border: 1px solid pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // max-width: 302px;
}

.panelContainer {
  padding: 1rem;
}

.metric {
  font-size: 1.65rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.positiveGreen {
  color: #04b485;
}

.negativeRed {
  color: #c2005a;
}
