@import '../../../../styles/scss/config';

.formBalanceWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94px;
  // border: 1px solid yellow;
  color: #d0d0d0;
  text-align: center;
  margin-bottom: 1rem;

  h1 {
    font-family: archialight;
    font-size: 1.75rem;
    color: #d0d0d0;
  }

  .balance {
    // border: 1px solid green;
    display: grid;
    place-items: center;
    padding: 0 2rem;
    font-family: archialight;
    font-size: 2rem;

    .notEnabled {
      padding: 0 1rem;
      color: #c2005a;
      font-size: 1.3rem;
    }
  }
}

.errorMessage {
  display: grid;
  place-items: center;
  padding: 0 1rem;
  // border: 1px solid green;
  font-family: archialight;
  font-size: 1.3rem;
  color: #c2005a;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
