.platformPageWrap {
  // border: 1px solid red;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 3rem;
}

.mainMetricsSection {
  margin-bottom: 4rem;
}
