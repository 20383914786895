.panelHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #202020;
  --aug-border-bg: #333;
  --aug-border-top: 0px;
  --aug-border-x: 0px;
  --aug-border-bottom: 2px;
  // --aug-br: 15px;
  // --aug-bl: 15px;
  --aug-tl: 5px;
  --aug-tr: 5px;
  padding: 1.75rem 0;
  font-size: 1.35rem;
  font-family: archialight;
  text-align: center;
}
