.container {
  opacity: 0.4;
  cursor: help;
}

.messageBox {
  background-color: #101010;
  border: 1px solid #292929;
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 0.875rem; // 14px
  font-family: 'archiathin';
  color: #d0d0d0;
  text-align: center;
}
