.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  padding: 1.5rem 1.5rem;
  z-index: 3;
}

.remove {
  display: none;
}

@media (min-width: 321px) {
  .overlay {
    align-items: center;
  }
}
