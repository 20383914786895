.linkBtn {
  display: inline-block;
  border: none;
  border-bottom: 1px solid;
  background: none;
  margin: 0;
  padding: 0;
  padding-bottom: 1px;
  font-size: 0.875rem;
  font-family: archiathin;
  color: #9f48f5;
  cursor: pointer;

  &:hover {
    border-bottom: none;
  }
}
