.togglePanelWrap {
  position: relative;
  // border: 1px solid green;
  // width: 400px;
  height: 100%;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 769px) {
  .togglePanelWrap {
    // min-width: 300px;
  }
}
