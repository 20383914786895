.loader {
  position: absolute;
  background-color: transparent;
  height: 100%;
  width: 100%;

  span {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  span:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      #121212 25%,
      #141414 25%,
      #141414 50%,
      #121212 50%,
      #121212 75%,
      #141414 75%,
      #141414
    );
    background-size: 100px 100px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    overflow: hidden;
    z-index: 5;
  }
}

.loader > span:after,
.animate > span > span {
  animation: load 1s linear infinite;
}

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 100px;
  }
}
