.sectionHeaderWrap {
  border: 2px solid #1a1a1a; // Don't remove; required for tour guide
  border-radius: 7px;
  background-color: #1a1a1a;
  padding: 0.5rem;
  text-align: center;
}

.sectionHeaderTitle {
  // border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: archiamedium;
  font-size: 1.45rem; // Same as Brand font-size in Header
  letter-spacing: 1.25px;
  line-height: 1.3;
}

.sectionHeaderDescription {
  font-family: archiathin;
  font-size: 15px;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 501px) {
  .sectionHeaderWrap {
    text-align: initial; // left-align
  }

  .sectionHeaderTitle {
    justify-content: initial; // flex-start
    font-size: 1.75rem;
  }

  .sectionHeaderDescription {
    font-size: 1.1375rem;
  }
}
