.wrap {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.wrap:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    #121212 25%,
    #141414 25%,
    #141414 50%,
    #121212 50%,
    #121212 75%,
    #141414 75%,
    #141414
  );
  background-size: 100px 100px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  overflow: hidden;
  z-index: 1;
}
