.iconButton {
  width: 28px;
  height: 28px;
  border: none;
  background: transparent;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: inherit;
}
