@import '../../../../../styles/scss/config';

.panelHeader {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  padding-top: 3.2rem;
  padding-bottom: 2.5rem;
}

.formBalanceWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94px;
  // border: 1px solid yellow;
  color: #d0d0d0;
  text-align: center;
  margin-bottom: 1rem;

  .formBalanceLabel {
    font-family: archialight;
    font-size: 1.75rem;
    color: #d0d0d0;
  }

  .formBalance {
    display: grid;
    place-items: center;
    padding: 0 2rem;
    // border: 1px solid green;
    font-family: archialight;
    font-size: 2rem;
    color: #cb6df7; // heliotrope
    text-shadow: 1px 1px 8px rgb(136, 70, 118),
      1px 1px 8px rgba(192, 54, 156, 1);

    .notEnabled {
      padding: 0 1rem;
      font-family: archialight;
      font-size: 1.3rem;
      color: #c2005a;
      text-shadow: none;
    }
  }
}

.selectStablecoinWrap {
  border-radius: 7px;
  box-shadow: 0 0 16px #fff;
}

.numberFormWrap {
  // border: 1px solid pink;
  // height: 234px; // !!!
  height: 251px;
  // padding-top: 3.5rem;
  padding-top: 2rem;
}

.numberInputWrap {
  width: 86%;
  margin: 0 auto 1.25rem auto;
}

.depositBtn {
  width: 86%;
  margin: 0 auto;
}

.earlyWithdrawalNotice {
  // border: 1px solid red;
  width: 86%;
  margin: 1rem auto;
  font-family: 'archiathin';
  font-size: 13px;
  text-align: center;
}

.percentageFormWrap {
  // border: 1px solid green;
  height: 251px;
  padding-top: 0.75rem;
}

.percentageFormContainer {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0.75rem auto;
  padding: 0.25rem;
}

.percentageInputWrap {
  // border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div:first-child {
    margin-right: 1.5rem;
  }
}

.percentageSliderWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border: 1px solid blue;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.slider {
  width: 90%;
  margin: 0.25rem auto;
}

.withdrawBtn {
  width: 86%;
  height: 65px;
  margin: 0 auto;
  font-size: 15px;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

// Tablets
@media (min-width: 501px) {
  .percentageFormWrap {
    padding-top: 1.75rem;
  }

  .percentageFormContainer {
    flex-direction: row;
    padding: initial;
    width: 86%;
    height: 90px;
  }

  .percentageInputWrap {
    display: block;
    width: initial;

    & > div:first-child {
      margin-right: initial;
    }
  }

  .percentageSliderWrap {
    align-items: center;
    margin-top: initial;
    margin-left: 1rem;
    padding-top: 1rem;
  }

  .slider {
    width: 100%;
    margin: initial;
    margin-bottom: 1.2rem;
  }

  .withdrawBtn {
    height: 75px;
  }
}

@media (min-width: 769px) {
  .percentageFormWrap {
    padding-top: 0.75rem;
  }

  .percentageFormContainer {
    flex-direction: column;
    width: initial;
    height: initial;
    padding: 0.25rem;
  }

  .percentageInputWrap {
    // border: 1px solid yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    & > div:first-child {
      margin-right: 1.5rem;
    }
  }

  .percentageSliderWrap {
    display: flex;
    align-items: initial;
    margin-top: 1rem;
    margin-left: initial;
    padding-top: initial;
  }

  .slider {
    width: 90%;
    margin: 0.25rem auto;
  }

  .withdrawBtn {
    height: 65px;
  }
}

@media (min-width: 1001px) {
  .percentageFormWrap {
    padding-top: 1.75rem;
  }

  .percentageFormContainer {
    flex-direction: row;
    width: 86%;
    height: 90px;
    padding: initial;
  }

  .percentageInputWrap {
    display: block;
    width: initial;

    & > div:first-child {
      margin-right: initial;
    }
  }

  .percentageSliderWrap {
    align-items: center;
    margin-top: initial;
    margin-left: 1rem;
    padding-top: 1rem;
  }

  .slider {
    width: 100%;
    margin: initial;
    margin-bottom: 1.2rem;
  }

  .withdrawBtn {
    height: 75px;
  }
}
