@import '../../../styles/scss/config';

.container {
  position: relative;
  height: fit-content;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3rem;
  --aug-border-all: 1px;
  --aug-b: 10px;
  --aug-t: 10px;
  --aug-r: 10px;
  --aug-l: 10px;
  --aug-b-extend1: 20%;
  --aug-t-extend1: 20%;
  --aug-r-extend1: 20%;
  --aug-l-extend1: 20%;
  --aug-border-bg: hsl(332, 100%, 38%);
  background-color: #14000a;
}

.primaryText {
  margin-top: 1rem;
  // font-size: 0.9rem;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 0.2em;
  color: rgba(256, 256, 256, 0.75);
}

.secondaryText {
  margin: 1rem auto;
  padding: 1em;
  text-align: center;
  color: $alert--light-2;
  background-color: $alert--dark-4;
}

.closeBtn {
  display: block;
  height: 35px;
  width: 35px;
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  background-color: transparent;
  background-size: 60%;
  background-image: url('../../../assets/close.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}
