.closeButtonWrap {
  position: absolute;
  top: 12px;
  right: 14px;
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  background-size: 60%;
  background-image: url('../../../../assets/close.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 3;

  &:focus {
    outline: 0;
  }
}

// Sizes

.small {
  top: 5px;
  right: 5px;
  width: 22px;
  height: 22px;
}

.medium {
  width: 35px;
  height: 35px;
}
