.metricTitle {
  // border: 1px solid green;
  display: flex;
  align-items: center;
  font-family: archiamedium;
  font-size: 1.2rem;
  text-align: center;
}

.metricValue {
  // border: 1px solid blue;
  text-align: center;
  font-family: archiathin;
  overflow: hidden;
  text-overflow: ellipsis;
}
