@import '../../../styles/scss/config';

.trxModalOverlay {
  z-index: 4;
}

.trxModal {
  width: 350px;
  height: fit-content;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  --aug-border-all: 2px;
  background-color: #1d174e;
}

.pending {
  --aug-border-bg: hsl(44, 96%, 41%);
  background-color: $warning--dark-4;
}

.failed {
  --aug-border-bg: hsl(332, 100%, 38%);
  background-color: $alert--dark-4;
}

.success {
  --aug-border-bg: hsl(164, 96%, 36%);
  background-color: $success--dark-4;
}

.closeBtn {
  display: block;
  height: 35px;
  width: 35px;
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  background-color: transparent;
  background-size: 60%;
  background-image: url('../../../assets/close.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.content {
  --aug-border-all: 1px;
  --aug-t-extend1: 50%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 2em 0 2em;
}

.pendingInner {
  --aug-border-bg: hsl(44, 96%, 41%);
  background-color: #140f01;
}
.successInner {
  --aug-border-bg: hsl(164, 96%, 36%);
  background-color: #010806;
}

.failedInner {
  --aug-border-bg: hsl(332, 100%, 38%);
  background-color: #14000a;
}

.loadingSpinnerContainer {
  padding: 2rem 0 0;
}

.trxMsg {
  margin: 1em auto 0.25em;
  font-size: 1.35rem;
  padding: 0.5em;
  text-align: center;
  display: flex;
  font-family: archiaregular;
  flex-direction: row;
  color: hsl(0, 0%, 91%);
  justify-content: center;
  align-items: center;
  width: 90%;
}

.successMsgWrap {
  display: flex;
  flex-direction: column;
}

.addtlSuccessInfo {
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.2em;
  font-family: archialight;
  font-size: 15px;
  text-align: center;
  color: #d0d0d0;
}

.addtlInfo {
  margin: 0.15rem auto;
  color: hsl(0, 0%, 80%);
  font-family: archialight;
  text-align: center;
  width: 85%;
  padding: 0.5rem;
}

.btnGroup {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .modalBtn {
    min-width: 120px;
  }
}

.btnWrap {
  width: 85%;
  margin: 1em auto 0;
}

.trxReceiptLink {
  border-bottom: 1px solid $success--light-3;
  padding-bottom: 0.75px;
}

.trxModalLink {
  color: #8e40dd;
  // text-decoration: underline;
}
