.numberInputWrap {
  position: relative;
  height: 48px;
  border: 2px solid #444;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.452) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.247) 0px 2px 6px 2px;
  background-color: #333;

  .numberInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    height: 100%;

    .numberInputField {
      flex-grow: 2;
      border: none;
      border-radius: 7px;
      background-color: transparent;
      width: 100%;
      padding: 0 0.5rem;
      font-family: archialight;
      font-size: 15px;
      color: #d0d0d0;

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &::placeholder {
        opacity: 1; /* Firefox */
        color: hsl(0, 0%, 60%);
      }
    }

    .inputButton {
      margin-right: 0.5rem;
    }
  }
}

.isDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 281px) {
  .numberInputWrap {
    .numberInputContainer {
      .numberInputField {
        font-size: 1.15rem;
      }
    }
  }
}
