.depositStablecoinsWrap {
  // border: 1px solid blue;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // grid-gap: 3rem;
  display: flex;
  justify-content: center;
  margin-top: 4.5rem;
  margin-bottom: 3.5rem;
  // margin-bottom: 5rem;
}

.metricList {
  & > div:first-child {
    margin-bottom: 3.5rem;
  }
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 769px) {
  .depositStablecoinsWrap {
    grid-template-rows: initial;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
}

@media (min-width: 1201px) {
  .depositStablecoinsWrap {
    grid-gap: 3rem;
  }
}
