.hamburgerWrap {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  background: none;
  margin: 0;
  margin-left: 0.5rem;
  padding: 0;
  cursor: pointer;
}

.hamburgerContainer {
  height: 24px;
  width: 24px;
  top: 9px;
  left: 8px;
  right: 8px;
  bottom: 10px;
  position: absolute;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //
@media (min-width: 319px) {
  .hamburgerWrap {
    margin-left: 1.5rem;
  }
}
