.selectPercentageWrap {
  // border: 1px solid pink;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 501px) {
  .selectPercentageWrap {
    display: flex;
    justify-content: space-between;
    grid-template-columns: initial;
    grid-gap: initial;
    width: 100%;
  }
}

@media (min-width: 601px) {
  .selectPercentageWrap {
    width: 80%;
  }
}

@media (min-width: 769px) {
  .selectPercentageWrap {
    display: grid;
    justify-content: initial;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.75rem;
    width: initial;
  }
}

@media (min-width: 1001px) {
  .selectPercentageWrap {
    display: flex;
    justify-content: space-between;
    grid-template-columns: initial;
    grid-gap: initial;
    width: 100%;
  }
}
