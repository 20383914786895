.pool2PageWrap {
  // border: 1px solid red;
  margin: 3rem 0 7rem 0;
}

.pageTourToggle {
  // border: 1px solid red;
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
}

// ------------------------- //
// ----- MEDIA QUERIES ----- //
// ------------------------- //

@media (min-width: 501px) {
  .pool2PageWrap {
    margin-bottom: 15rem;
  }
}
